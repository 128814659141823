<template>
  <div class="paratext">
    <div style="display: flex;justify-content: space-between;margin-right: 60px">
      <div class="paratext_div1">
        <span>关键字:</span>
        <el-input placeholder="请输入" v-model="Key" style="width: 180px"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="queryList">查询</el-button>
      </div>
      <el-button type="primary" icon="el-icon-plus" @click="addPost">提问</el-button>
    </div>
    <div>
      <el-radio-group v-model="leftRight" style="margin-top: 21px;" @change="allQuestions">
        <el-radio-button label="left" style="font-size: 16px" >全部提问</el-radio-button>
        <el-radio-button label="right" style="font-size: 16px" >我的提问</el-radio-button>
      </el-radio-group>
    </div>
    <div style="height: 630px;overflow-y: scroll;overflow-x: hidden;">
      <div v-if="tableData.length == 0" style="text-align: center;margin-top: 40px">
        <span style="font-size: 16px;color: #666666">暂无数据</span>
      </div>
      <div class="reply-content-2" v-for="(item,index) in tableData" :key="index">
        <el-divider></el-divider>
        <div style="margin-left: 23px;display: flex;justify-content: space-between;margin-right: 20px;">
          <el-tooltip placement="right-start" effect="light" v-if="UserId == item.UserId">
            <div slot="content">
              <div style="display: flex;flex-flow: column;width: 60px;">
                <el-link
                  style="margin-right: 11px;margin-bottom: 20px"
                  type="text"
                  :underline="false"
                  @click="handleEdit(item)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link>
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click="handleDelete(index, item)"
                ><i class="el-icon-delete"></i>删除</el-link>
              </div>
            </div>
            <el-button type="text" @click="see(index, item)" style="font-size: 18px;color: #333;margin-bottom: 10px">{{index+1}}.{{item.FAQTitle}}</el-button>
          </el-tooltip>
          <span @click="see(index, item)" style="font-size: 18px;color: #333;margin-bottom: 10px" v-if="UserId != item.UserId">{{index+1}}.{{item.FAQTitle}}</span>
          <el-link :underline="false" @click="see(index, item)">
            <i class="el-icon-chat-dot-round" style="font-size: 20px;margin-right: 6px"></i>{{item.Num}}人已回复</el-link>
        </div>
        <div class="reply-content-time" style="margin-left: 22px;margin-bottom: 4px">
          <span style="background-color: #409EFF">Q:</span>
          <span style="font-size: 16px;color: #666666;font-weight: 400;margin-left: 23px">{{item.FAQText | ellipsis}}</span>
        </div>
        <div class="reply-content-time" style="margin-left: 22px">
          <span style="background-color: #67c23a" v-show="item.ReplyText">A:</span>
          <span style="font-size: 16px;color: #666666;font-weight: 400;margin-left: 23px">{{item.ReplyText | ellipsis}}</span>
        </div>

        <div style="text-align: right;margin-right: 18px" class="right-text">
          <img src="../assets/image/taskmanagement/projectleader.png" alt="">
          <span>提问人：{{item.UserName}}</span>
          <span style="margin-left: 6px;margin-right: 6px">{{item.HospitalName}}</span>
          <img src="../assets/image/taskmanagement/time.png" alt="">
          <span>提问时间：{{item.CreateDate}}</span>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="getInfoFaq.PageIndex + 1"
          :page-size="13"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    <el-dialog
      :visible.sync="dialogVisible">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">创建提问</span>
      </div>
      <el-form ref="addForm" label-width="100px" :model="addForm" :rules="rules">
        <el-form-item label="提问标题:" prop="FAQTitle">
          <el-input v-model="addForm.FAQTitle"></el-input>
        </el-form-item>
        <el-form-item label="提问内容:" prop="FAQText">
          <el-input type="textarea" v-model="addForm.FAQText" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
        </el-form-item>
        <el-form-item label="附件:">
                 <el-upload
                    class="upload-demo"
                    accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                    multiple
                    action="#"
                    :file-list="filelist"
                    :auto-upload="false"
                    :on-change="Problemfeedback"
                    >
                        <el-button size="medium" type="primary" style="margin-left: 10px;">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">不超过50MB</div>
                  </el-upload>  
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button  @click="addCancel('addForm')">取消</el-button>
        <el-button  @click="onSubmit('addForm')">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible1"
    >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改提问</span>
      </div>
      <el-form ref="editForm" label-width="100px" :model="editForm" :rules="rules">
        <el-form-item label="提问标题:" prop="FAQTitle">
          <el-input v-model="editForm.FAQTitle"></el-input>
        </el-form-item>
        <el-form-item label="提问内容:" prop="FAQText">
          <el-input type="textarea" v-model="editForm.FAQText" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
        <el-form-item label="附件:">
                 <el-upload
                    class="upload-demo"
                    accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                    multiple
                    action="#"
                    :file-list="filelist1"
                    :auto-upload="false"
                    :on-change="Problemfeedback1"
                    >
                        <el-button size="medium" type="primary" style="margin-left: 10px;">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">请打印签字页，签名并拍照上传</div>
                  </el-upload>
                   <div v-for="(item,index) in Filecollection" :key="index">
                        <span>{{item.FileName}}.{{item.Suffix}}</span><span style="margin-left: 20px;cursor:pointer;" @click="Deletefile(item)">x</span>
                  </div>      
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button  @click="editCancel('editForm')">取消</el-button>
        <el-button type="primary" @click="onSubmit1('editForm')">确定</el-button>
      </div>
    </el-dialog>
    <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="handleDelDialog"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该提问，是否继续？</span>
        </div>
        <div class="btn">
          <el-button  @click="handleDelDialog = false">取消</el-button>
          <el-button @click="submitDel">确定</el-button>
        </div>
      </el-dialog>
    </div>
     <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="dialogVisible2"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的文件，是否继续？</span>
        </div>
        <div class="btn">
          <el-button  @click="dialogVisible2 = false">取消</el-button>
          <el-button @click="oksubmitDel">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { addInsert, delProblem, editUpdate ,GetListFaq } from "../api/problemBack.js"
import {mapState} from "vuex";
import reseacrh from "@/api/research";
import api from "../api/Grantsandmilestones"
const requiredRule = [{ required: true, message: '输入框不能为空', trigger: 'blur' }]
export default {
  data(){
    return{
      filelist:[],
      filelist1:[],
      UserId: sessionStorage.getItem('Id'),
      leftRight: "left",
      Key: "",
      rules: { // 验证规则
        FAQTitle: requiredRule,
        FAQText: requiredRule
      },
      editForm: { //编辑提问的数据
        Id: "",
        FAQTitle: "",
        FAQText: "",
      },
      addForm: { // 增加提问的参数
        pid: 0,
        TopicId: "",
        FAQTitle: "",
        FAQText: "",
        UserId:0, 
        Flag:0
      },
      getInfoFaq: { //返回FAQ列表分页参数
        pid: 0,
        key: "",
        UserId: 0,
        TopicId: "",
        PageIndex:0,
        PageSize:5
      },
      problemProjedtId: "",
      delItem: {}, //要删除的对象
      handleDelDialog: false,//删除弹框
      dialogVisible:false,
      dialogVisible1:false,
      tableData: [],
      PageCount: 0, // 分页的总数
      filearr:[],
      filearr1:[],
      Filecollection:[],
      dialogVisible2:false,
      item:{},
      DataGuid:"",
    }
  },
  created(){
    reseacrh.topic(this.projectId).then(res => { // 刷新表格数据
      if (res.data.ExtraData.ProjectId == 0) {
        this.problemProjedtId = res.data.ExtraData.Id
        this.getListProblem()
      } else {
        this.problemProjedtId = res.data.ExtraData.ProjectId
        this.getListProblem()
      }
    })
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 150) {
        return value.slice(0, 150) + "...";
      }
      return value;
    },
  },
  computed: mapState(["projectId"]),
  methods:{
    oksubmitDel(){//确定删除文件列表
       reseacrh.alldelet({Id:this.item.Id}).then(res=>{
            console.log(res);
            if(res.data.Status==1){
               this.$message.success(res.data.Message)
              api.preFinancFile(this.DataGuid).then(res=>{
               if(res.data.Status==1){
                 console.log(res,"@@@@@@");
                 this.Filecollection=res.data.ExtraData
                 this.dialogVisible2=false
               }else{
                 alert(res.data.Message)
               }

        })
            }else{
              alert(res.data.Message)
            }
       })
    },
    Deletefile(objitem){//打开文件列表
      this.dialogVisible2=true
      this.item=objitem
    },
    Problemfeedback1(file, fileList){
      this.filearr1=fileList
      let total=0
      this.filearr1.forEach(item=>{
         total+=item.size
     })
     const isLt2M = total / 1024 / 1024 < 50;
     if(!isLt2M){
         this.filelist1=[]
         return  this.$message.error('不超过50MB');
     }
    },
    Problemfeedback(file, fileList){
      console.log(fileList);
     this.filearr=fileList
     let total=0
     this.filearr.forEach(item=>{
         total+=item.size
     })
     const isLt2M = total / 1024 / 1024 < 50;
     if(!isLt2M){
         this.filelist=[]
         return  this.$message.error('不超过50MB');
     }
    },
    see(index,item){
      this.$emit('Topic-DataGuid', item) // 传递数据
    },
    queryList() {
      this.getListProblem()
    },
    allQuestions() {
      if (this.leftRight == "left") {
        this.getInfoFaq.UserId = 0
      }
      if (this.leftRight == "right") {
        this.getInfoFaq.UserId = this.UserId
      }
      console.log(this.getInfoFaq.UserId,'zhi')
      this.getListProblem()
    },
    getListProblem() { //返回FAQ列表分页
      // this.getInfoFaq.TopicId = this.projectId
      this.getInfoFaq.key = this.Key
      this.getInfoFaq.TopicId = this.problemProjedtId
     GetListFaq(this.getInfoFaq).then(res => {
       console.log(res,"列表 ");
       if(res.data.Status==1){
         this.tableData=res.data.Entity
         this.PageCount=res.data.TotalRowsCount
         console.log(this.tableData,'回复')
       }else{
         alert(res.data.Message)
       }
      })
    },
    handleEdit(item){//打开修改帖子弹框
    console.log(item);
    this.filelist1=[]
     this.DataGuid=item.DataGuid
      let {Id, FAQTitle, FAQText,Pid} = item
      this.editForm = {Id,FAQTitle,FAQText,Pid}
      this.editForm.UserI=0
      this.editForm.Flag=0
      console.log(this.editForm,"修改")
      this.dialogVisible1=true
      api.preFinancFile(item.DataGuid).then(res=>{
               console.log(res,"文件");
               if(res.data.Status==1){
                 this.Filecollection=res.data.ExtraData
               }else{
                 alert(res.data.Message)
               }

        })
    },
    addPost(){//打开创建帖子列表
      this.dialogVisible=true
    },
    handleCurrentChange(val){ // 分页
      this.getInfoFaq.PageIndex = val -1
      this.getListProblem()
      console.log(`当前页: ${val}`);
    },
    addCancel(formName) { // 新增取消
      this.dialogVisible = false
      this.$refs[formName].resetFields();
    },
    editCancel(formName) { // 编辑取消
      this.dialogVisible1 = false
      this.$refs[formName].resetFields();
    },
    onSubmit1(formName){//确定修改提问
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fds = new FormData();
          this.filearr1.forEach(file => {
              fds.append('file',file.raw)
         })
         fds.append("data", JSON.stringify(this.editForm));
          editUpdate(fds).then(res => {
            if(res.data.Status==1){
              this.getListProblem()
              this.$message.success(res.data.Message)
              this.dialogVisible1 = false
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.data.Message)
            }
          })
        } else {
          return false;
        }
      });
    },
    onSubmit(formName){//确定新增提问
      // this.addForm.TopicId = this.projectId
      this.addForm.TopicId = this.problemProjedtId
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.filearr.forEach(file => {
              fd.append('file',file.raw)
         })
         fd.append("data", JSON.stringify(this.addForm));
          addInsert(fd).then(res => {
            console.log(res);
            if(res.data.Status==1){
              this.getListProblem()
              this.$message.success(res.data.Message)
              this.dialogVisible = false
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.data.Message)
            }
          })
        } else {
          return false;
        }
      });
    },
    submitDel() { // 确认删除弹框
     delProblem({Id:this.delItem.Id}).then(res=>{
        if(res.data.Status==1){
          console.log(res,'删除')
          this.handleDelDialog = false
          this.getListProblem()
          this.$message.success(res.data.Message)
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    handleDelete(index,item){ // 打开删除弹框
      this.handleDelDialog = true
      this.delItem = item
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/mixin.scss";
.paratext{
  width: 100%;
  height: 640px;
  /deep/ .el-divider--horizontal {
    margin: 12px 0;
    background: 0 0;
    border: 1px dashed #DCDFE6;
    height: 0;
  }
  .paratext_div1{
    width: 100%;
    span {
      font-weight: 400;
      //line-height: 24px;
      color: #666666;
      @include add-size($font_size_16);
      margin-right: 10px;
    }
    .el-input {
      @include add-size($font_size_16);
    }
    .el-button{
      margin-left: 10px;
    }
  }
  .paratext_div2{
    width: 99%;
    height: 580px;
    margin-left: 1%;
  }
}
/deep/.el-dialog {
  width: 510px;
}
.block {
  margin-top: 20px;
}
.paratext_div2 {
  ::-webkit-scrollbar {
    width:8px!important;
    height: 4px;

  }
  ::-webkit-scrollbar-thumb {
    background-color: #1890ff;
    border-radius: 10px;
  }
}
.yuan-qiu1 {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #FF9900;
  border-radius: 50%;
  opacity: 1;
}
.yuan-qiu2 {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #31ACFA;
  border-radius: 50%;
  opacity: 1;
}
.right-text{
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    opacity: 1;
  }
}
img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
</style>


import axios from "./Interceptor.js"
let mUrl =window.global_config.BASE_URL
import qs from "qs"

// 新增提问
export const addInsert = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFAQ/Insert',
        data,
    })
}

// 修改提问
export const editUpdate = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFAQ/Update',
        data,
    })
}

// 删除提问
export const delProblem = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFAQ/Delete',
        data,
    })
}

// 返回FAQ对象
export const getProblem = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'TopicFAQ/Get',
        params: data
    })
}

// 返回FAQ列表分页
export const GetListFaq = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFAQ/GetPaged',
        data:qs.stringify(info)
    })
}

// 完结FAQ
export const FAQFinish = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFAQ/FAQFinish',
        data,
    })
}

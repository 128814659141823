<template>
  <div class="contents">
    <div class="contents_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="contents_dnf" v-if="show">
      <feedback-list @Topic-DataGuid="DataGuidClick" :key="DataGuidId"></feedback-list>
    </div>
    <div class="contents_dnf" v-show="!show">
      <div class="contents_dnf1">
        <div class="contents_right">
          <div class="content-user">
            楼主
          </div>
          <div class="content-BBsName">
            <img src="../../../assets/image/taskmanagement/projectname.png" alt="">
            <span class="span_18" v-if="obj.FAQTitle">{{obj.FAQTitle}}</span>
          </div>
          <div style="display: flex;margin-left: 26px;margin-top: 4px;">
            <div style="margin-right: 30px">
              <img src="../../../assets/image/taskmanagement/projectleader.png" alt="">
              <span>提问人:</span>
              <span>{{userName}}</span>
              <!--              <span>{{obj.UserName}}</span>-->
            </div>
            <div>
              <img src="../../../assets/image/taskmanagement/time.png" alt="">
              <span>提问时间:</span>
              <span>{{obj.CreateDate}}</span>
            </div>
          </div>
          <div style="position: absolute;right: 20px;top: 30px">
            <el-button @click="show = true" type="primary" style="margin-right: 10px">返回上一级</el-button>
            <el-button icon="el-icon-lock" type="primary" v-if="obj.Flag == 0" @click="setFinishType">未完结</el-button>
            <el-button icon="el-icon-lock" type="primary" v-else>已完结</el-button>
          </div>
          <div class="reply-content">
            <el-input
              class="content-BBSText"
              readonly
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3}"
              v-model="obj.FAQText">
            </el-input>
            <div @click="reply" class="reply-icon" v-if="obj.Flag == 0" style="cursor:pointer;">反馈TA</div>
          </div>
          <div>
              <p style="margin-left:30px;cursor:pointer;" v-for="(item,index) in Filecollection" :key="index" @click="Viewdownload(item)">
                 <span>附件:</span><span>{{item.FileName}}.{{item.Suffix}}</span>
              </p>
          </div>
        </div>
      </div>
      <div style="height: 500px;overflow-y: scroll;overflow-x: hidden">
        <div class="reply-content-2" v-for="(item,index) in datalist" :key="index" :class="{'isActive':item.Flag == 1}">
          <div class="reply-user">
            <p>{{item.UserName}}</p>
          </div>
          <div class="reply-content-time">
            <div style="display: flex;justify-content: space-between">
              <div>
                <img src="../../../assets/image/taskmanagement/time.png" alt="">
                <span>发表时间:</span>
                <span>{{item.CreateDate}}</span>
                <img src="../../../assets/image/problemyou.png" alt="" v-show="item.Flag == 1" style="margin-left: 20px">
                <span v-show = "item.Flag == 1" style="margin-left: 10px;color: #3978E7">最优解答</span>
              </div>
              <div>
                <img src="../../../assets/image/problemset.png" alt="" style="width: 16px;height: 16px;">
                <el-link style="margin-right: 21px" :underline="false" @click="setYes(index,item)" v-show="item.Flag == 0">设为最优解答</el-link>
                <el-link style="margin-right: 21px" :underline="false" @click="setNo(index,item)" v-show="item.Flag == 1">取消最优解答</el-link>
                <el-link style="margin-right: 21px" :underline="false" @click="Modifypost(item)"><i class="el-icon-edit"></i>修改</el-link>
                <el-link style="margin-right: 10px" :underline="false" @click="delpost(item.Id)"><i class="el-icon-delete"></i>删除</el-link>
              </div>
            </div>
            <el-input
              class="content-BBSText"
              readonly
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3}"
              v-model="item.FAQText">
            </el-input>
             <div v-for="(i,index) in item.list" :key="index" style="cursor:pointer;" @click="okitem(i)"> 
                  <span>附件:</span><span>{{i.FileName}}</span>.<span>{{i.Suffix}}</span>
             </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="replyPageList.PageIndex + 1"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <div class="discussionArea-dialogVisible">
      <el-dialog
        :visible.sync="dialogVisible"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">反馈</span>
        </div>
        <el-form ref="form" label-width="120px">
          <el-form-item label="反馈内容:" required>
            <el-input type="textarea" v-model="addForm.FAQText" placeholder="请输入"></el-input>
          </el-form-item>
           <el-form-item label="附件:">
                 <el-upload
                    class="upload-demo"
                    accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                    multiple
                    action="#"
                    :file-list="filelist"
                    :auto-upload="false"
                    :on-change="Problemfeedback"
                    >
                    <el-button size="medium" type="primary" style="margin-left: 10px;">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">不超过50MB</div>
                  </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="oncancel">取消</button>
          <button  @click="onSubmit">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="discussionArea-dialogVisible">
      <el-dialog
        :visible.sync="dialogVisible1"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改</span>
        </div>
        <el-form ref="editForm" label-width="100px" :model="editForm">
          <el-form-item label="回复内容:" required>
            <el-input type="textarea" v-model="editForm.FAQText" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
          </el-form-item>
          <el-form-item label="附件:">
                  <el-upload
                    class="upload-demo"
                    accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                    multiple
                    action="#"
                    :file-list="filelist1"
                    :auto-upload="false"
                    :on-change="Problemfeedback1"
                    >
                    <el-button size="medium" type="primary" style="margin-left: 10px;">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">请打印签字页，签名并拍照上传</div>
                  </el-upload>
                  <div v-for="(item,index) in Filecollection1" :key="index">
                        <span>{{item.FileName}}.{{item.Suffix}}</span><span style="margin-left: 20px;cursor:pointer;" @click="Deletefile(item)">x</span>
                  </div>    
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button  @click="oncancel1">取消</el-button>
          <el-button  @click="onSubmit1">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="handleDelDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该反馈，是否继续？</span>
        </div>
        <div class="btn">
          <el-button  @click="handleDelDialog = false">取消</el-button>
          <el-button @click="submitDel">确定</el-button>
        </div>
      </el-dialog>
    </div>
        <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="filehandleDelDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该文件，是否继续？</span>
        </div>
        <div class="btn">
          <el-button  @click="filehandleDelDialog = false">取消</el-button>
          <el-button @click="filesubmitDel">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import reseacrh from "@/api/research";
import api from "../../../api/Grantsandmilestones"
import apis from "../../../api/research"
import apiss from "../../../api/url"
import FeedbackList from "@/components/FeedbackList";
import {  addInsert, delProblem, editUpdate ,GetListFaq, getProblem, FAQFinish} from "@/api/problemBack.js"
export default {

  data(){
    return{
      filehandleDelDialog:false,
      filelist:[],
      filelist1:[],
      addFinish: {
        Id: "",
        entities: []
      },
      problemset: false,
      editForm: { //编辑反馈的数据
        Id: "",
        FAQTitle: "",
        FAQText: "",
        UserId:0,
        Flag:0,
      },
      addForm: { // 增加提问的参数
        pid: "",
        TopicId: "",
        FAQTitle: "",
        FAQText: "",
        UserId:0,
        Flag:0,
      },
      FAQText: "", // 反馈内容
      replyPageList: { //返回FAQ列表分页参数
        pid: "",
        key: "",
        TopicId: "",
        PageIndex:0,
        PageSize:5
      },
      DataGuidId: "1", // 默认key的值
      userName: "",
      delID: null, // 删除反馈的Id
      handleDelDialog: false,//删除弹框
      show: true,
      dialogVisible:false,
      dialogVisible1:false,
      dialogVisible2:false,
      obj:{},
      datalist:[],
      idList: [], // 提交完结时候优解的id
      id:"",
      DataId: "",
      PageCount: 0,
      problemProjedtId: "",
      Filecollection:[],
      Filecollection1:[],
      filearr:[],
      filearr1:[],
      DataGuid:"",
      item:"",
    }
  },
  created(){
    this.topiclist()
  },
  computed: mapState(["projectId"]),
  components:{
    treedata,
    FeedbackList
  },
  methods:{
       topiclist(){
            reseacrh.topic(this.projectId).then(res => { // 刷新表格数据
              if (res.data.ExtraData.ProjectId == 0) {
                this.problemProjedtId = res.data.ExtraData.Id
              } else {
                this.problemProjedtId = res.data.ExtraData.ProjectId
              }
            })
       },
       Problemfeedback1(file, fileList){
          this.filearr1=fileList
          this.filearr=fileList
          let total=0
          this.filearr1.forEach(item=>{
              total+=item.size
          })
          const isLt2M = total / 1024 / 1024 < 50;
          if(!isLt2M){
              this.filelist1=[]
              return  this.$message.error('不超过50MB');
          }
       },
       okitem(item){
         apis.preview(item.Id).then(res=>{
            console.log(res);
            if(res.data.Status==1){
                    if(res.data.ExtraData.Suffix=="jpg"||res.data.ExtraData.Suffix=="png"||res.data.ExtraData.Suffix=="pdf"){
                            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url)
                    }else{
                      apiss.filepermissionsData(item.Id).then(res=>{
                        if(res.status==200){
                          let params={
                            Id:item.Id
                          }
                      apiss.filedownloadData(params).then(res=>{
                          let data = res.data;
                          let filename = decodeURIComponent(res.headers.filename);
                          this.downLoadXls(data, filename);
                      })
                        }
                      })
                    }
            }else{
                alert(res.data.Message)
            }
          })
       },
       Problemfeedback(file, fileList){
          this.filearr=fileList
          let total=0
          this.filearr.forEach(item=>{
              total+=item.size
          })
          const isLt2M = total / 1024 / 1024 < 50;
          if(!isLt2M){
              this.filelist=[]
              return  this.$message.error('不超过50MB');
          }
       },
       downLoadXls(data, filename) {
      var blob = new Blob([data], { type: "application/vnd.ms-excel" }); //接收的是blob，若接收的是文件流，需要转化一下
      if (typeof window.chrome !== "undefined") {
        // Chrome version
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.download = filename;
        link.click();
      } else if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE version
        var blobs = new Blob([data], { type: "application/force-download" });
        window.navigator.msSaveBlob(blobs, filename);
      } else {
        // Firefox version
        var file = new File([data], filename, {
          type: "application/force-download",
        });
        // link.href="http://192.168.54.119/debug/api/TopicData/DownloadDataFile"
        // alert(URL.createObjectURL(file),"这是给啥");
        window.open(URL.createObjectURL(file));
      }
    },
    Viewdownload(item){
          apis.preview(item.Id).then(res=>{
            console.log(res);
            if(res.data.Status==1){
                    if(res.data.ExtraData.Suffix=="jpg"||res.data.ExtraData.Suffix=="png"||res.data.ExtraData.Suffix=="pdf"){
                            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url)
                    }else{
                      apiss.filepermissionsData(item.Id).then(res=>{
                        if(res.status==200){
                          let params={
                            Id:item.Id
                          }
                      apiss.filedownloadData(params).then(res=>{
                          let data = res.data;
                          let filename = decodeURIComponent(res.headers.filename);
                          this.downLoadXls(data, filename);
                      })
                        }
                      })
                    }
            }else{
                alert(res.data.Message)
            }
          })
          

    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.show = true
      this.$store.commit('SaveId',data)
      reseacrh.topic(this.projectId).then(res => { // 刷新表格数据
        this.DataGuidId = res.data.ExtraData.DataGuid
      })
    },
    DataGuidClick(data) { //提问列表传递过来的id
      this.replyPageList.pid = data.Id
      this.replyPageList.TopicId = this.problemProjedtId
      this.addFinish.Id = data.Id
      this.addForm.pid = data.Id
      this.addForm.TopicId = this.problemProjedtId
      this.userName = data.UserName
      this.show = false
      this.postreply()
      this.discuss()
    },
    problemDelete(Id) {
      let index = this.idList.indexOf(Id)
      this.idList.splice(index,1)
    },
    setYes(index,item) { //设置为最优解的
      item.Flag = 1 // 将默认到取消
      this.datalist.splice(index,1,item)
      let Id = item.Id
      if (this.idList.indexOf(Id) == -1) {
        this.idList.push(Id)  // 将数组中没有的Id添加到数组
      }
    },
    setNo(index,item) { //取消最优解
      item.Flag = 0 // 将默认到最设置
      this.datalist.splice(index,1,item)
      let Id = item.Id
      this.problemDelete(Id) // 删除数组里面的Id
    },
    discuss() {
      getProblem({Id: this.replyPageList.pid }).then(res=>{//单个提问的数据
        this.obj=res.data.ExtraData
        api.preFinancFile(this.obj.DataGuid).then(res=>{
               console.log(res,"文件");
               if(res.data.Status==1){
                 this.Filecollection=res.data.ExtraData
               }else{
                 alert(res.data.Message)
               }

        })
        console.log(this.obj,'对象')
      })
    },
    jie(){
      this.dialogVisible2=false
    },
    handleCurrentChange(val){ // 分页
      this.replyPageList.PageIndex = val -1
      this.postreply()
    },
    postreply(){ //反馈的数据
      GetListFaq(this.replyPageList).then(res => {
        if(res.data.Status==1){
          this.datalist=res.data.Entity.map(item=>{
                  api.preFinancFile(item.DataGuid).then(res=>{
                    console.log(res,"文件");
                    this.$set(item,"list",res.data.ExtraData)
              })
              return item
          })

          // this.datalist = this.datalist.map(item =>{
          //   item.isActive = true
          //   return item
          // })
          console.log(this.datalist,"反馈数据")
          this.PageCount=res.data.TotalRowsCount
        }else{
          alert(res.data.Message)
        }
      })
    },
    reply(){//打开反馈按钮
      this.dialogVisible=true
    },
    onSubmit(){//确认反馈
          let fd = new FormData();
          this.filearr.forEach(file => {
              fd.append('file',file.raw)
         })
         fd.append("data", JSON.stringify(this.addForm));
      addInsert(fd).then(res => {
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.dialogVisible = false
          this.postreply()
          this.addForm.FAQText = ""
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    oncancel(){//关闭确认反馈按钮
      this.dialogVisible=false
    },
    filesubmitDel(){//确定删除
       reseacrh.alldelet({Id:this.item.Id}).then(res=>{
            console.log(res);
            if(res.data.Status==1){
               this.$message.success(res.data.Message)
              api.preFinancFile(this.DataGuid).then(res=>{
               if(res.data.Status==1){
                 console.log(res,"@@@@@@");
                 this.Filecollection1=res.data.ExtraData
                 this.filehandleDelDialog=false
                 this.postreply()
               }else{
                 alert(res.data.Message)
               }

        })
            }else{
              alert(res.data.Message)
            }
       })
    },
    Deletefile(item){
        this.item=item
        this.filehandleDelDialog=true
    },
    Modifypost(item){//打开修改提问弹框
      // this.BBSText=BBSText
      this.DataGuid=item.DataGuid
      this.filelist1=[]
      let {Id, FAQTitle, FAQText} = item
      this.editForm = {Id,FAQTitle,FAQText}
      this.dialogVisible1=true
       api.preFinancFile(item.DataGuid).then(res=>{
               console.log(res,"文件");
               if(res.data.Status==1){
                 this.Filecollection1=res.data.ExtraData
               }else{
                 alert(res.data.Message)
               }

        })
    },
    onSubmit1(){//确认修改提问
          let fds = new FormData();
          this.filearr1.forEach(file => {
              fds.append('file',file.raw)
         })
         fds.append("data", JSON.stringify(this.editForm));
      editUpdate(fds).then(res => {
        if(res.data.Status==1){
          this.postreply()
          this.$message.success(res.data.Message)
          this.dialogVisible1 = false
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    oncancel1(){//关闭修改回复弹框
      this.dialogVisible1=false
    },
    submitDel() {
      delProblem({Id:this.delID}).then(res=>{
        if(res.data.Status==1){
          this.handleDelDialog = false
          this.postreply()
          this.$message.success(res.data.Message)
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    delpost(id){
      this.handleDelDialog = true
      this.delID = id
    },
    setFinishType() { // 设置完结
      this.addFinish.entities = this.idList // 选择数组的Id
      console.log(this.addFinish,'数组')
      FAQFinish(this.addFinish).then(res => {
        if(res.data.Status==1){
          this.postreply()
          this.discuss()
          this.$message.success(res.data.Message)
        } else {
          this.$message.error(res.data.Message)
        }

      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/discussion_area.scss";
</style>
